'use strict'

function initialize(): void {
  document.body.addEventListener(
    'click',
    (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const button = target.closest('[data-seo-link-href]')
      if (button === null) {
        return
      }

      // Ignored links are useful to keep obfuscation while allowing custom
      // scripts to handle click events.
      const ignored = button.getAttribute('data-seo-link-ignored')
      if (ignored === 'ignored') {
        return
      }

      const encoded = button.getAttribute('data-seo-link-href')
      if (encoded !== null) {
        /** The value is encoded using base64. */
        const decoded = atob(encoded)
        if (decoded !== null) {
          /** Handle the case where the link is set as external. */
          if (button.classList.contains('external-link')) {
            try {
              event.preventDefault()
              event.stopPropagation()

              const windowOpen = window.open()
              if (windowOpen) {
                windowOpen.opener = null
                windowOpen.location.assign(decoded)

                return
              }
            } catch (exception) {}
          }
          window.location.assign(decoded)
        }
      }
      /** We listen to the event in the capture phase to catch it before the click listener in core/common. */
    },
    true
  )
}

export default (): void => {
  initialize()
}
